import React,{ useEffect, useState } from "react";
import { TextField,DropDownMenu,SubmitButton, FullContainer, Text, WhatsAppButton, Icon, NumField } from "../widgets/widgets.js";
import { Center, Col, Row } from "../widgets/layout_orient";
import { JSSend, Qst, mapToObject, thisFormData, tlGET, tl_alert } from "../@assets/tlBootstrap/tlBootstrap/JS/tl-js.js";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getGreeting, loading, makeCall, waMeUrl } from "../function.js";
import { Capacitor } from '@capacitor/core';

import "./recharge.css";
import { useUserData } from "../userContext.js"; 

let rFrDt

export default function Recharge () {
    const { couponType } = useParams();
    const platform = Capacitor.getPlatform();
    let [stepIndex,setStepIndex] = useState("1");
    let [methode,setMethode] = useState("mtnbj");
    var [amountVal,setamountVal] = useState(0);
    let [codeToExec,setcodeToExec] = useState("*880*41*176986*0#");
    
    const [userData,set__] = useUserData();

    let [texted,setTexted] = useState("Votre opération de recharge est initialisée veuillez entrer l'id de la transaction pour terminer");
    let [color,] = useState("tl_color-grey"); 
    let [finishMod,setFinishMod] = useState(false);
    let minm = couponType==="1xbet"?200:900

    // let [validName,setValidName] = useState(true);
    let [validId,setValidId] = useState(true);
    let [validNum,setValidNum] = useState(true);
    let [validTel,setValidTel] = useState(true);
    let [validAmonut,setValidAmount] = useState(true);
    
    // let [realValidName,setRealValidName] = useState(false);
    let [realValidId,setRealValidId] = useState(false);
    let [realValidNum,setRealValidNum] = useState(false);
    let [realValidTel,setRealValidTel] = useState(false);
    let [realValidAmount,setRealValidAmount] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('tk');
    const Nav = useNavigate();

    async function returnToHome (e) {
        e.preventDefault()
        // window.location.replace(window.location.origin+"/home")
        window.history.pushState(null,null,tlGET.origin+"/home")
        await loading(2000);
        Nav(process.env.PUBLIC_URL+"/home",{replace:true})
        
    }
    useEffect(() => {
        if (token && token.length > 12) {
            setStepIndex("2")
        }
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/getter.php",
            {"u_infos":true,"u_id":userData.id},
            (result,response)=>{
               if (result) {
                    if (response.status) {
                        // console.log(response.result)
                        set__(response.result)
                    }
               }
            }
        )
    },[]);
    function whenBlured (e) {
        if (e.value <= 0) {
            setValidId(true)
            setValidNum(true)
            setValidAmount(true)
        }
    }
    function isValidId (e) {
        if (e.value.length < 5 && e.value.length > 0 ) {
            setValidId(false)
        }
        else {
            setValidId(true)
            if (e.value.length < 5 ) {
                setRealValidId(false)
            }
            else {
                setRealValidId(true)
            }
        }
    }
    function isValidAmount (e) {
        setamountVal(e.value)
        if (methode === "moovbj") {
            setcodeToExec("*855*4*1*177859*"+e.value+"#")
        }
        else {
            setcodeToExec("*880*41*176986*"+e.value+"#")
        }
        if (parseInt(e.value) < minm && e.value.length > 0 ) {
            setValidAmount(false)
        }
        else {
            setValidAmount(true)
            if (parseInt(e.value) < minm ) {
                setRealValidAmount(false)
            }
            else {
                setRealValidAmount(true)
            }
        }
    }
    // function isValidName (e) {
    //     if (e.value.length < 4 && e.value.length > 0 ) {
    //         setValidName(false)
    //     }
    //     else {
    //         setValidName(true)
    //         if (e.value.length < 4 ) {
    //             setRealValidName(false)
    //         }
    //         else {
    //             setRealValidName(true)
    //         }
    //     }
    // }
    function isValidTel (ehid,e) {
        
        var input = e.value.replace(/\D/g, '');  // Supprime tout ce qui n'est pas un chiffre
        // Utilise regex pour formater par groupes de deux chiffres
        var formatted = input.slice(0,10).replace(/(\d{2})(?=\d)/g, '$1 ');
        e.value = formatted;
        if (ehid.value.length < 13 && ehid.value.length > 5 ) {
            setValidTel(false)
        }
        
        else {
            setValidTel(true)
            if (ehid.value.length < 13 ) {
                setRealValidTel(false)
            }
            else {
                setRealValidTel(true)
            }
        }
    }
    function isValidNum (e) {
        
        if (e.value.length < 5 && e.value.length > 0 ) {
            setValidNum(false)
        }
        
        else {
            setValidNum(true)
            if (e.value.length < 5 ) {
                setRealValidNum(false)
            }
            else {
                setRealValidNum(true)
            }
        }
    }
    function onSelected (value) {
        setMethode(value)
        if (value === "moovbj") {
            setcodeToExec("*855*4*1*177859*"+amountVal+"#")
        }
        else {
            setcodeToExec("*880*41*176986*"+amountVal+"#")
        }
    }
    function submitting (e) {
        e.preventDefault()
        let theForm = e.target.thisParent()
        let dst = mapToObject(new Map(new thisFormData(theForm).entries()))
        
        dst[e.target.value] = e.target.value
        if (token !== undefined && token !== null && token.length > 12) {
            dst["token"] = token
        }
        else {
            dst["token"] = rFrDt
        }
        // console.log(dst)
        
        // console.log(rFrDt)
        if (!theForm.hasAttribute("inauth")) {
            if (realValidNum) {
                theForm.setAttribute ("inauth","true")
                Qst('process-loader').setAttribute('inLoad',true)
                JSSend(
                    'POST',
                    "https://app.depotbetpro.com/backend/request.php",
                    dst,
                    (result,response)=>{
                        if (result) {
                            if (response.status) {
                                setTimeout(() => {
                                    Qst('process-loader').removeAttribute('inLoad')
                                    setFinishMod(true)
                                    setTexted("Votre opération de recharge est en cours de traitement,vous serez notifier une fois traité")
                                }, 2000);
                            }
                            else if (response.result === "Er0" ) {
                                Qst('process-loader').removeAttribute('inLoad')
                                theForm.removeAttribute ("inauth")
                                tl_alert('Alert',"Assurez-vous de remplir tous les champs comme il se doit")
                            }
                            else if (response.result === "InErr0" ) {
                                Qst('process-loader').removeAttribute('inLoad')
                                theForm.removeAttribute ("inauth")
                                tl_alert('Erreur',"Une erreur s'est produite lors de l'opération")
                            }
                        }
                        else {
                            Qst('process-loader').removeAttribute('inLoad')
                            theForm.removeAttribute ("inauth")
                            tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                        }
                    }
                )
                
                
            }
            else {
                if (!realValidNum) {
                    setValidNum(false)
                }
                // if (!realValidName) {
                //     setValidName(false)
                // }
                if (!realValidTel) {
                    setValidTel(false)
                }
                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                    `<div class='row centry'>
                        <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                    </div> 
                    Assurez-vous de bien remplir les champs comme il se doit!`
                )
            }
        }
        
    }
    async function setSecondSetp (e) {
        e.preventDefault()
        let theForm = e.target.thisParent()
        let dtOb = new thisFormData (theForm)
        let DT = mapToObject(new Map (dtOb.entries()))
        DT['id']=userData.id
        function sendR (data) {
            JSSend(
                'POST',
                "https://app.depotbetpro.com/backend/request.php",
                data,
                (result,response)=>{
                    if (result) {
                        if (response.status) {
                            setTimeout(() => {
                                rFrDt = response.result
                                setStepIndex("2")
                                Qst('process-loader').removeAttribute('inLoad')
                            }, 5000);
                        }
                        else if (response.result === "Er0" ) {
                            Qst('process-loader').removeAttribute('inLoad')
                            theForm.removeAttribute ("inauth")
                            tl_alert('Alert',"Assurez-vous de remplir tous les champs comme il se doit")
                        }
                        else if (response.result === "InErr0" ) {
                            Qst('process-loader').removeAttribute('inLoad')
                            theForm.removeAttribute ("inauth")
                            tl_alert('Erreur',"Une erreur s'est produite lors de l'opération")
                        }
                    }
                    else {
                        Qst('process-loader').removeAttribute('inLoad')
                        theForm.removeAttribute ("inauth")
                        tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                    }
                }
            )
        }
        
        if (!theForm.hasAttribute("inauth")) { 
            if (realValidAmount && realValidId && realValidTel) {
                theForm.setAttribute ("inauth","true")
                Qst('process-loader').setAttribute('inLoad',true)
                if (platform === 'ios') {
                    await makeCall(codeToExec)
                    sendR(DT)
                } else if (platform === 'android') {
                    await makeCall(codeToExec)
                    sendR(DT)
                }
                else {
                    tl_alert ("Infos","Tapez ce code sur votre téléphone <strong class='tl_color-white'>"+codeToExec+"</strong>",
                        ()=>{
                            sendR(DT)
                        }
                    )
                }
                
                
            }
            else {
                if (!realValidId) {
                    setValidId(false)
                }
                if (!realValidAmount) {
                    setValidAmount(false)
                }
                // if (!realValidName) {
                //     setValidName(false)
                // }
                if (!realValidTel) {
                    setValidTel(false)
                }
                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                    `<div class='row centry'>
                        <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                    </div> 
                    Assurez-vous de bien remplir les champs comme il se doit!`
                )
            }
        }
        
    }
    const numOptions = [
        { name: "Bénin", code: "BJ", value: "+229", src:process.env.PUBLIC_URL+"/images/flag-bj.png" },
        // { name: "Burkina Faso", code: "BF", value: "+226",src:process.env.PUBLIC_URL+"/images/flag-bf.png"  },
        // { name: "Côte d'Ivoire", code: "CI", value: "+225",src:process.env.PUBLIC_URL+"/images/flag-ci.png"  },
        // { name: "Gabon", code: "GA", value: "+241",src:process.env.PUBLIC_URL+"/images/flag-ga.png"  },
        // { name: "Niger", code: "NE", value: "+227", src:process.env.PUBLIC_URL+"/images/flag-ne.png" },
        // { name: "Togo", code: "TG", value: "+228",src:process.env.PUBLIC_URL+"/images/flag-tg.png" }
    ];
    const options = [
        { name: "MTN Bénin", value: "mtnbj" },
        { name: "Moov Bénin", value: "moovbj" },
    ]
    return (
        <Row className="rechargeThumb" thumb={stepIndex}>
            <Col className="tl_mt-60 form-slot tl_position-relative">
                {  
                    userData.reg_allowed === "no"?
                    <FullContainer>
                        <Center className="tl_flex-column">
                            <img alt="imag" src={process.env.PUBLIC_URL+"/images/assistance.gif"} className="circular" width={200} height={200} />
                            <Text className={color+" transition-all-ease-2 tl_fs-16 tl_text-center tl_ph-20"} content={"Oups! Vous n'êtes actuellement pas autorisé à effectuer des opérations. Veuillez contacter notre service technique pour en savoir plus"} />
                            <WhatsAppButton action={waMeUrl({number:"22962727170",message:`${getGreeting()}, c'est ${userData.reg_uname},\n
j'ai remarqué que mon compte a été bloqué, je voudrais en savoir plus`})}><Row className="centry tl_gap-10"><Text content="Nous contacter"/><img alt="imag" src={process.env.PUBLIC_URL+"/images/wame.gif"} className="circular" width={50} height={50} /></Row></WhatsAppButton>
                        </Center>
                    </FullContainer>: 
                    (couponType === "1xbet"?
                    <><FullContainer>
                        <Center>
                            <img alt="imag" src={process.env.PUBLIC_URL+"/images/recharge.gif"} className="circular" width={100} height={100} />
                        </Center>
                    </FullContainer>
                
                    
                    <form className='recharge-form tl_mt-30 tl_mb-60 tl_gap-20'>
                        <TextField name="bet_id" type="number" invalidText="au moins 5 caractères" isValid={validId} onInput={isValidId} onFocus={isValidId} label={ "ID "+couponType } isRequired={true}/>
                        <TextField name="bet_montant" isValid={validAmonut} onInput={isValidAmount} onFocus={isValidAmount} invalidText={"au moins "+minm+" F"} type="number" label="Montant" isRequired={true}/>
                        <input type="hidden" name="coupon_type" value={couponType} />
                        {/* <input type="hidden" name="s_num" value={userData.reg_utel} /> */}
                        <input type="hidden" name="s_name" value={userData.reg_uname} />
                        <input type="hidden" name="u_r" value="u_r" />
                        <NumField options={numOptions} name="s_num" isValid={validTel} invalidText="numéro invalid" onBlur={whenBlured} onFocus={isValidTel} onInput={isValidTel} label="Numéro de dépôt"/>
                        {/* <TextField name="s_name" invalidText="au moins 4 caractères" onBlur={whenBlured} isValid={ validName } onInput={isValidName} onFocus={isValidName} label="Nom complet" isRequired={true}/> */}
                        <DropDownMenu options={options} onSelected={(ele)=>{setMethode(ele.value);onSelected(ele.value)}} name="r_method" label="moyen de recharge"/>
                        <div className="r_note" show-r-note="true">
                            en appuyant sur le bouton continuer ci-dessous, ce code {<strong>{codeToExec}</strong>} sera lancé,(si le numéro de recharge n'est pas dans l'appareil veuillez tapez le code dans l'appareil où se trouve le numéro de rechage.)
                        </div>
                        <SubmitButton type="button" text="continuer" id="cont" subType="cont" onClick={setSecondSetp} className="tl_mt-10"/>
                        <div style={{minHeight:"30px", width:'100%'}}>
                            
                        </div>
                    </form></>
                    :
                    <FullContainer>
                        <Center className="tl_flex-column">
                            <img alt="imag" src={process.env.PUBLIC_URL+"/images/assistance.gif"} className="circular" width={200} height={200} />
                            <Text className={color+" transition-all-ease-2 tl_fs-16 tl_text-center tl_ph-20"} content={"Veuillez contacter notre service technique pour en savoir plus sur comment effectué une recharge de "+couponType} />
                            <WhatsAppButton action={waMeUrl({number:"22962727170",message:`${getGreeting()}, c'est ${userData.reg_uname},\n
je voudrais effectué une recharge de ${couponType}`})}><Row className="centry tl_gap-10"><Text content="Nous contacter"/><img alt="imag" src={process.env.PUBLIC_URL+"/images/wame.gif"} className="circular" width={50} height={50} /></Row></WhatsAppButton>
                        </Center>
                    </FullContainer>)
                }
                {/* <Col className="tl_position-absolute tl_bottom-12">
                    <Text content={"by ©thinolord | version 1.0.7"} className="tl_w-100 tl_text-center tl_color-grey" />
                </Col> */}
            </Col>
            <Col className="tl_mt-30 form-slot tl_position-relative">
                <FullContainer>
                    <Center className="tl_flex-column">
                        <img alt="imag" src={!finishMod ? process.env.PUBLIC_URL+"/images/loaded.gif":process.env.PUBLIC_URL+"/images/timer2.gif"} className="circular" width={180} height={180} />
                        <Text className={color+" transition-all-ease-2 tl_fs-16 tl_text-center tl_ph-20"} content={texted} />
                        {!finishMod ?  <></>: <a className="wabtn" rel="noopener noreferrer" href="" onClick={returnToHome}><Row className="tl_align-items-center tl_gap-10"><Icon icon="bxs-left-arrow"/> Retour </Row></a>}
                    </Center>
                </FullContainer>
                
                {!finishMod ? <form className='recharge-form tl_mt-50 tl_gap-30'>
                    <div className="r_note" show-r-note="true" style={{backgroundColor:"var(--app-primary-color)"}}>
                        {<><strong>IMPORTANT</strong><br/></>}
                        Le code {<strong>{codeToExec}</strong>} a été lancé, (si le numéro de recharge n'est pas dans l'appareil veuillez tapez le code dans l'appareil où se trouve le numéro de rechage.)
                    </div>
                    <TextField name="recharge_id" type="text" invalidText="au moins 5 chiffres" onBlur={whenBlured} isValid={ validNum } onInput={isValidNum} onFocus={isValidNum} label="Transaction id" isRequired={true}/>
                    <SubmitButton type="button" text="Terminer l'opération" value="up_r" name="up_r" id="r_" subType="r_" onClick={submitting} className="tl_mt-10"/>
                </form> : <></>}
                {/* <Col className="tl_position-absolute tl_bottom-8">
                    <Text content={"by ©thinolord | version 1.0.7"} className="tl_w-100 tl_text-center tl_color-grey" />
                </Col> */}
            </Col>
        </Row>
        
    )
}
