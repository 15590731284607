import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Auth from './Screens/auth';
// import AskAge from './Screens/askAge';
import Home from './Screens/home';
import Admin from './Admin';
import WithDraw from './Screens/withdraw';
import Recharge from './Screens/recharge';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Routes,Route,Link } from 'react-router-dom';
import { UserDataProvider } from './userContext';
// import { AdminNotify } from './Screens/adminNotify';
import Notifier from './adNotif';
import { PushNotificationListeners } from './notificationListener';
import { TLGlobalPlatform } from './function';

function blockInspect(event) {
  if (event.keyCode === 123 || (event.ctrlKey && event.shiftKey && event.keyCode === 73)) {
      event.preventDefault()
  }
}
window.addEventListener('keydown', blockInspect)
window.addEventListener('contextmenu', (e)=>{
  e.preventDefault()
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserDataProvider>  {/* Encapsule les composants avec le UserDataProvider */}
        { TLGlobalPlatform === "android" ? <PushNotificationListeners /> :null }
        <Routes>
          {/* <Route path='/' element={<AskAge/>}></Route> */}
          <Route path='/' element={<Auth/>}></Route>
          <Route path='/Admin' element={<Admin/>}></Route>
          <Route path='/Admin/:action/:userId' element={<Notifier/>} >
          </Route>
          <Route path='/Admin/:action/:userId/:userName' element={<Notifier/>} >
          </Route>
          <Route path='/home' element={<Home/>}></Route>
          <Route path='/App/:action' element={<App />}>
            <Route path='/App/:action/:couponType' element={<WithDraw/>}></Route>
            <Route path='/App/:action/:couponType/_' element={<Recharge/>}></Route>
          </Route>
          <Route path="*" element={
              <main className='full centry tl_col'>
                <h4 className="tl_w-100 tl_fs-18 tl_mb-20 tl_color-red tl_text-center">!!!</h4>
                <Link className="tl_color-grey tl_text-decoration-none tl_bg-dark tl_p-10-30 tl_border-radius-10 transition-all-ease-2 tl_color-white-hover tl_bg-red-hover" to="/">Retour en lien sûr <i className='bx bx-chevron-left tl_color-white'></i></Link>
              </main>
            }>
          </Route>
        </Routes>
      </UserDataProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// Thinolord V1.0.7
reportWebVitals();
