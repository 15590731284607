
import { useEffect,useState } from "react";
import { useUserData } from "./userContext";
import { Col,Row } from "./widgets/layout_orient";
import { FullContainer, Icon, ListeTile, Text, Title } from './widgets/widgets';
import { HistoricView } from "./Screens/Views/historicView";
import { ProfilView } from "./Screens/Views/profilView";
import { AdminHomeView } from "./Screens/AdminViews/adminHomeView";
import { Qst,Qs, JSSend, objectToList, mapToObject } from "./@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { Link, useNavigate } from "react-router-dom";
import { ClientView } from "./Screens/AdminViews/clientView";
// import { useParams,Outlet } from "react-router-dom";

import "./Admin.css";
import { askPerm, loading, tl_confirme } from "./function";

let previousView = 0
let uhistoId = 0

let timeAd = 0
let varH
let ck = ''
let cv = ''
let cId = "*"
let Hinterval
let Usrs = []
export default function Admin () {
   const nav = useNavigate()
   // const { action } = useParams();
   const [userData,setUserData] = useUserData({})
   let [, setHist] = useState([])
   let [varhist, setvarHist] = useState([])
   let [histbull, sethistbull] = useState(0)
   let [userbull, setuserbull] = useState(0)
   let [, setcurrentId] = useState("*")
   let [,setdataUser] = useState([])
   let [, setcurrentFilterKey] = useState("")
   let [, setcurrentFilterVal] = useState("")

  function getUser () {
      JSSend(
          'POST',
          "https://app.depotbetpro.com/backend/getter.php",
          {"g_u":true},
          (result,response)=>{
              if (result) {
                  
                  Usrs = objectToList(response.result)
                  setdataUser(Usrs)
                  // console.log(Usrs)
                  setuserbull(Usrs.length)
              }
          }
      )
  }
//    const location = useLocation();
//   let uId = 0
//   const queryString = new URLSearchParams(location.search);
//   uId = queryString.get('userId') || "*";
   function getHist () {
      JSSend(
         'POST',
         "https://app.depotbetpro.com/backend/getter.php",
         {"g_h":true,"u_id":"*"},
         (result,response)=>{
            if (result) {
               // console.log(objectToList(response.result))
               setHist(objectToList(response.result))
               varH = objectToList(response.result)
               sethistbull(varH.length)
               setvarHist(varH.filtre("id",cId).searched(ck,cv))
            }
         }
      )
   }
   function getHistC () {
      JSSend(
         'POST',
         "https://app.depotbetpro.com/backend/getter.php",
         {"g_h":true,"u_id":"*"},
         (result,response)=>{
            if (result) {
               varH = objectToList(response.result)
               sethistbull(varH.length)
            }
         }
      )
   }
  function globloop() {
      clearInterval(Hinterval)
      
      setTimeout(() => {
         Hinterval = setInterval(() => {
            getUser()
            setTimeout( getHist, 100);
         }, 6000);
      }, 100);
   }
   useEffect (()=>{
      if ( userData === null || userData === undefined ||  !userData.hasOwnProperty("reg_uname") || !userData.hasOwnProperty("reg_utel") || !userData.hasOwnProperty("reg_umail") || !userData.hasOwnProperty("reg_urol") ) {
         nav("/",{replace:true})
         return
      }
      else if ( userData.reg_urol !== "admin") {
         nav("/home",{replace:true})
         return
      }
      if (timeAd === 0) {
         getUser()
         setInterval(getHistC, 2000);
         setInterval(getUser, 6000);
         setTimeout(() => {
            JSSend(
               'POST',
               "https://app.depotbetpro.com/backend/getter.php",
               {"g_h":true,"u_id":"*"},
               (result,response)=>{
                   if (result) {
                     getHist()
                   }
               }
           )
         }, 500);
         // globloop()
         timeAd ++
      }
      setTimeout(() => {
         Qst("global-loader").removeAttribute("inLoad")
      }, 1500);
   },[])
   let [currentIndex, setCurrentIndex] = useState(0)
   async function deconnectUser () {
      if (await tl_confirme("","Vous êtes sur le point de vous déconnectez")) {
         await loading (2000)
         setUserData({})
         nav(process.env.PUBLIC_URL+"/",{replace:true})
      }
   }
   
  
   async function userIsto (id,withclikck=true) {
         // uhistoId=id
      // JSSend(
      //    'POST',
      //    "https://app.depotbetpro.com/backend/getter.php",
      //    {"g_h":true,"u_id":id},
      //    (result,response)=>{
      //       if (result) {
      //          setvarHist(objectToList(response.result))
      //       }
      //    }
      // )
      setcurrentId(id)
      cId = id
      setvarHist(varhist.filtre("id",id))
      if (withclikck) {
         Qs("hist").click()
      }

   }
   async function changeView (ele) {
      // globloop()
      
      Qs(".nav-items").forEach((item) => {
         item.removeClass("item-active")
      })
      if (!ele.containsClass("item-active")) {
         
         // ele.addClass('item-active')
      }
      setCurrentIndex(parseInt(ele.getData("view-index")))
      previousView = parseInt(ele.getData("view-index"))
      let indic = Qs("bottom-nav-indicator")
      // const eleTop = ele.offsetTop+"px"
      // const eleLeft = ele.offsetLeft+"px"
      const eleContent = ele.innerHTML
      let tsx = 100*parseInt(ele.getData("view-index"))
      if (ele.getData("view-index") === "2" ) {
         globloop()
         getHist()
      }
      else {
         clearInterval(Hinterval)
         ck = ""
         cv = ""
         setcurrentFilterKey("")
         setcurrentFilterVal("")
      }
      indic.stylesThis({
         transform: `translateX(${tsx}%)`
      })
      // tl_alert("",uId)
      if ( ele !== Qs('hist')) {
         setTimeout(() => {
            userIsto("*",false)
            cId = "*"
         }, 200);
      }
      setTimeout(() => {
         indic.htmlThis(eleContent)
         
      }, 0);
   }
   
   useEffect (()=>{
      
      // console.log(userData.hasOwnProperty(reg_umail) && userData.hasOwnProperty(reg_uname) && userData.hasOwnProperty(reg_utel))
      if ( !userData.hasOwnProperty("reg_umail") && !userData.hasOwnProperty("reg_uname") && !userData.hasOwnProperty("reg_utel")){
         nav("../",{replace:true})
      }
      askPerm("")
      setTimeout(() => {
         Qs(".nav-items","click",changeView)
         if (uhistoId !== 0 && uhistoId !== null) {
           setTimeout(() => {
            Qs("hist").click()
           }, 200);
         }
         else {
            Qs(".nav-items").forEach((item) => {
               if (item.containsClass("item-active")) {
                  item.click()
               }
            })
         }
      }, 500);
   },[])
   
   function sH (k,v) {
      ck = k
      cv = v
      setcurrentFilterKey(k)
      setcurrentFilterVal(v)
      setvarHist(varH.filtre("id",cId).searched(k,v))
      // mutH = varH.searched(k,v)
   }
   const infosData = [
      {named: "Admin",},
      {named: "Profil",},
      {named: "Historiques",},
      {named: "Clients",},
   ]
   function openParam () {
      if (!Qs("adminScreen").hasAttribute('slide-active')){
         Qs("adminScreen").setAttribute('slide-active',"true")
      }
      else {
         Qs("adminScreen").removeAttribute('slide-active')
      }
   }
   // const Nav = useNavigate();

   //  async function returnToAdmin () {
   //      await loading(2000);
   //      Nav("/Admin",{replace:true})
   //  }
   return (
      <Row className="adminScreen" lmd="">
         
         <tl-slider>
            <Col className="tl_h-100">
               <div className="userDrawerHeader">
                  <img alt="imag" src={process.env.PUBLIC_URL+"/images/edit.gif"} onClick={()=>{setCurrentIndex(1);openParam();setTimeout(()=>{changeView(Qst('[data-view-index="1"]'))},300) }} width={25} className="editIcon"/>
                  {/* <Icon icon="bx-edit-alt" onClick={()=>{setCurrentIndex(1);openParam();setTimeout(()=>{changeView(Qst('[data-index="1"]'))},300) }} className="editIcon"/> */}
                  <Row className="tile">
                     <div className="user_avatar centry"><img alt="imag" src={process.env.PUBLIC_URL+"/images/user.gif"} width={55}/></div>
                     {/* <div className="user_avatar centry"><Icon icon="bx-user"/></div> */}
                     <div className="before_avatar">
                        <Col className="">
                           <Title content={userData.reg_uname} className="tl_color-white drawer-overflow ellipis"/>
                           <Text content={userData.reg_umail} className="drawer-email tl_color-grey drawer-overflow ellipis"/>
                        </Col>
                     </div>
                  </Row>
                  <Row className="tl_ph-10 tl_align-items-center">
                     <Icon icon="bxs-phone" className="tl_fs-18"/><Text content={": "+userData.reg_utel}/>
                  </Row>
               </div>
               <Row className="tl_ph-10 tl_mt-30 tl_mb-10 tl_align-items-center tl_h-18px">
                  <Text content={"Coordonnées"} className="tl_color-grey"/><Icon icon="bxs-chevron-right" className="tl_fs-16 tl_color-grey"/>
               </Row>
               <ListeTile>
                  <div className="tile_leading centry"><Icon icon="bxs-envelope"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"mailto:contact@depotbetpro.com"}>contact@depotbetpro.com</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon icon="bxs-phone"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"tel:+22962727170"}>+229 62 72 71 70</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon icon="bx-map-pin"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={"Abomey-Calavi, Bénin"} className=""/>
                  </div>
               </ListeTile>
               <Row className="tl_ph-10 tl_mt-30 tl_mb-10 tl_align-items-center tl_h-18px">
                  <Text content={"Liens Rapides"} className="tl_color-grey"/><Icon icon="bxs-chevron-right" className="tl_fs-16 tl_color-grey"/>
               </Row>
               <ListeTile>
                  <div className="tile_leading centry"><Icon style={{color:"var(--app-secondary-color)"}} icon="bxs-badge-check"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"https://depotbetpro.com/mentions-legales/"} target="_blank" rel="noopener noreferrer">Mentions Légales</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon style={{color:"rgb(10,10,40"}} icon="bxs-check-shield"/></div>
                  <div className="before_avatar">
                     <Title calibre={4} content={<Link className="quick-link" to={"https://depotbetpro.com/politiques-de-confidentialite/"} target="_blank" rel="noopener noreferrer">Politique de confidentialitée</Link>} className=""/>
                  </div>
               </ListeTile>
               <ListeTile>
                  <div className="tile_leading centry"><Icon style={{color:"rgb(10,10,40"}} icon="bxs-info-circle"/></div>
                  <div className="before_avatar">
                  <Title calibre={4} content={<Link className="quick-link" to={"https://depotbetpro.com/conditions-generales/"} target="_blank" rel="noopener noreferrer">Condition générale</Link>} className=""/>
                  </div>
               </ListeTile>
            </Col>
            <Col>
               <Text content={"by ©thinolord | version 1.0.7"} className="tl_w-100 tl_mb-15 tl_text-center tl_color-grey" />
               
               <FullContainer className="tl_ph-10 deconn" onClick={deconnectUser}>
                  <Row className="tl_align-items-center deconn_rw">
                     <div className="deconn_avatar centry"><Icon icon="bxs-log-out-circle"/></div><Text content="Déconnexion" className="tl_fw-bold tl_fs-14"/>
                  </Row>
               </FullContainer>
            </Col>
            
         </tl-slider>
         <Col className="mainScreen">
            <div onClick={openParam} className='none-modal'></div>
            <Col className="mainScreenC">
               
               <div className='top-navigation-bar'>
                  <div className='nav-top'>
                     <Row className='leading tl_gap-20'>
                        <img alt="imag" src={process.env.PUBLIC_URL+"/images/logo_anim_dark.gif"} width={100} height={100}/>
                        <div className='Named ellipis tl_w-100 tl_max-w-100'> {infosData[currentIndex].named} </div>
                        <Icon  icon="bxs-cog" onClick={openParam} className="threaling tl_fs-22 tl_cursor-pointer"/>
                     </Row>
                     
                  </div>
               </div>
               <Row className="screenSlotThumb tl_align-items-start" sst={currentIndex+""}>
                  
                  <Col className="screenSlot adminView">
                     {currentIndex === 0 ? <AdminHomeView nUser={userbull} nHist={histbull} onClient={()=>{setCurrentIndex(3)}} onTransaction={()=>{changeView(Qs("hist"))}}/> : <></>}
                  </Col>

                  <div className="profilContainer screenSlot">
                     {currentIndex === 1 ? <ProfilView /> : <></>}
                  </div>

                  <FullContainer className="screenSlot histoView">
                     {currentIndex === 2 ? <HistoricView onSearch={sH} dtHisto={varhist} /> : <></>}
                  </FullContainer>

                  <div className="screenSlot clientView">
                     {currentIndex === 3 ? <ClientView onHistoView={userIsto}/> : <></>}
                  </div>
               </Row>
            </Col>
            {/* Bar de navigation bas */}
            <div className='bottom-navigation-bar'>
               <div className='nav'>
                  <div className="tl_cursor-pointer bottom-nav-indicator" onClick={()=>{setCurrentIndex(previousView)}}></div>
                  <button className='nav-items item-active' data-view-index="0"> <Col className='tl_gap-5 tl_align-items-center'><Icon icon='bxs-home'/> Acceuil <div className='navindicator'></div></Col> </button>
                  <button className='nav-items prof' data-view-index="1"> <Col className='tl_gap-5 tl_align-items-center'><Icon icon='bxs-user'/> Profil <div className='navindicator'></div></Col> </button>
                  <button className='nav-items hist' data-view-index="2"> <Col className='tl_gap-5 tl_align-items-center'><Icon icon='bx-transfer-alt'/> Transactions <div className='navindicator'></div></Col></button>
               </div>
            </div>
         </Col>
      </Row>
   );
};
