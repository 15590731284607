
import { useEffect, useState } from "react";
import { Col, Row } from "../../widgets/layout_orient";
import { Icon, NumField, SubmitButton, Text, TextAction, TextField } from "../../widgets/widgets";
import { isValidMail,tl_alert,thisFormData,mapToObject, Qs, JSSend, Qst } from "../../@assets/tlBootstrap/tlBootstrap/JS/tl-js";
import { useUserData } from "../../userContext";
import "./profilView.css";
import { authenticate,confirm_edition, tl_confirme } from "../../function";
import { Capacitor } from '@capacitor/core';

// import { bioAuthentificator } from "../../function";


let numNiem = 0
let mailNiem = 0
let nameNiem = 0
let passNiem = 0

let timed = 0

export function ProfilView ({screenClass="mainScreenNoafter"}) {
    
    const platform = Capacitor.getPlatform();
    const [userData, setUserData] = useUserData();
    // console.log(userData)
    let [validName,setValidName] = useState(true);
    let [validMail,setValidMail] = useState(true);
    let [validPass,setValidPass] = useState(true);
    let [validOldPass,setValidOldPass] = useState(true);
    let [validNewPass,setValidNewPass] = useState(true);
    let [validNum,setValidNum] = useState(true);
    let [oldPass,setOldPass] = useState("");
    let [newPass,setNewPass] = useState("");

    let [realValidName,setRealValidName] = useState(false);
    let [realValidMail,setRealValidMail] = useState(false);
    let [realValidPass,setRealValidPass] = useState(false);
    let [realValidOldPass,setRealValidOldPass] = useState(false);
    let [realValidNewPass,setRealValidNewPass] = useState(false);
    let [realValidNum,setRealValidNum] = useState(false);

    function focusAct () {
        Qs("bottom-navigation-bar").hideThis()
    }

    function isValidName (e,ev) {
        if (nameNiem === 0) {
            ev.preventDefault()
            e.value = userData.reg_uname
            nameNiem++
        }
        if (e.value.length < 2 && e.value.length > 0 ) {
            setValidName(false)
            // console.log("valid name")
        }
        else {
            setValidName(true)
            if (e.value.length < 2 ) {
                setRealValidName(false)
            }
            else {
                setRealValidName(true)
            }
        }
        focusAct()
        // setName (e.value)
    }
   
    
    function isValidNum (ehid,e) {
        if (numNiem === 0) {
            e.value = userData.reg_utel.split(" ")[1]
            ehid.value = userData.reg_utel
            numNiem++
        }
        else {
            focusAct()
        }
        var input = e.value.replace(/\D/g, '');  // Supprime tout ce qui n'est pas un chiffre
        // Utilise regex pour formater par groupes de deux chiffres
        var formatted = input.slice(0,10).replace(/(\d{2})(?=\d)/g, '$1 ');
        e.value = formatted;
        if (ehid.value.length < 13 && ehid.value.length > 5 ) {
            setValidNum(false)
        }
        
        else {
            setValidNum(true)
            if (ehid.value.length < 13 ) {
                setRealValidNum(false)
            }
            else {
                setRealValidNum(true)
            }
        }
        
    }
    
    function isValidPassword (e,ev) {
        if (passNiem === 0) {
            ev.preventDefault()
            e.value = userData.reg_upswd
            e.disabled = true
            passNiem++
        }
        if (e.value.length >= 8) {
            setValidPass(true)
            setRealValidPass(true)
        }
        else {
            setValidPass(false)
            setRealValidPass(false)
        }
        // focusAct()
    }
    function isValidNewPassword (e) {
        setNewPass(e.value)
        if (e.value.length >= 8) {
            setValidNewPass(true)
            setRealValidNewPass(true)
        }
        else {
            setValidNewPass(false)
            setRealValidNewPass(false)
        }
        focusAct()
    }
    function isValidOldPassword (e) {
        setOldPass(e.value)
        if (e.value.length >= 8) {
            setValidOldPass(true)
            setRealValidOldPass(true)
        }
        else {
            setValidOldPass(false)
            setRealValidOldPass(false)
        }
        focusAct()
    }
    
    function whenBlured (e) {
        if (e.value <= 0) {
            setValidMail(true)
            setValidPass(true)
            setValidNum(true)
            setValidName(true)
            setValidOldPass(true)
            setValidNewPass(true)
        }
        Qs("bottom-navigation-bar").showThis("block")
    }
    
    function mailCheck (e,ev) {
        if (mailNiem === 0) {
            ev.preventDefault()
            e.value = userData.reg_umail
            mailNiem++
        }
        var input = e.value.toLowerCase();
        if (!isValidMail(input) && input.length > 0) {
            setValidMail(false)
        }
        else {
            setValidMail(true)
            if (isValidMail(input) ) {
                setRealValidMail(true)
            }
            else {
                setRealValidMail(false)
            }
        }
        e.value = input;
        focusAct()
    }
    async function submitting (e) {
        e.preventDefault()
        let theForm = e.target.thisParent()
        
        if (realValidMail && realValidName && realValidNum && realValidPass) {
            // theForm.setAttribute ("inauth","true")
            let thisData = new Map(new thisFormData(theForm).entries())
            let userDt = mapToObject(thisData)
            // console.log(userDt)
            let conf = await confirm_edition("","Entrez votre mot de passe pour confirmer la modification",userData.id)
            // console.log(conf)
            if (conf.statu) {
                // setUserData(userDt)
                // console.log(userDt)
                Qst('process-loader').setAttribute('inLoad',true)
                JSSend(
                    'POST',
                    "https://app.depotbetpro.com/backend/updater.php",
                    userDt,
                    (result,response)=>{
                        if (result) {
                            Qst('process-loader').removeAttribute('inLoad')
                            if (response.status) {
                                setUserData(response.result)
                                tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>mise à jour",
                                    `<div class='row centry'>
                                        <i class='bx bxs-check-circle tl_fs-22' style='color: var(--app-secondary-color)'></i>
                                    </div> 
                                    vos informations ont bien été mise à jour 😊!`
                                )
                            }
                            else {
                                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                                    `<div class='row centry'>
                                        <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                    </div> 
                                    Oups! Une erreur s'est produite lors de la mise à jour de vos informations`
                                )
                            }
                            // console.log(response.result)
                        }
                    }
                )
                
                return
            }
            else {
                if (conf.wrongauth === null) {
                    return
                }
                if (conf.wrongauth) {
                    tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                        `<div class='row centry'>
                            <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                        </div> 
                        Mot de passe incorrect !,impossible de vous authentifier`
                    )
                }
                else {
                    tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                        `<div class='row centry'>
                            <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                        </div> 
                        Impossible de vous authentifier`
                    )
                }
            }
        }
        else {
            if (!realValidMail) {
                setValidMail(false)
            }
            if (!realValidName) {
                setValidName(false)
            }
            if (!realValidNum) {
                setValidNum(false)
            }
            if (!realValidPass) {
                setValidPass(false)
            }
            tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                `<div class='row centry'>
                    <i class='bx bx-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                </div> 
                Assurez-vous de bien remplir les champs comme il se doit!`
            )
        }
        
        
    }
    async function forgetPass (e) {
        let dt = {}
        dt["fg_pass"] = "true"
        dt["u_tel"] = userData.reg_utel
        // console.log(dt)
        if (!await tl_confirme("Mot de passe oublié ?","En confirmant votre mot de passe sera réinitialisé et vous sera envoyé à <span style='color: var(--app-primary-color)'>"+userData.reg_umail+"</span>")) {
            return
        }
        if (userData.reg_utel.length < 13) {
            tl_alert("","Impossible de vous identifiez par votre numéro")
            return
        }
        Qst('process-loader').setAttribute('inLoad',true)
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/updater.php",
            dt,
            (result,response)=>{
                // console.log(response)
                if (result) {
                    Qst('process-loader').removeAttribute('inLoad')
                    if (response.status) {
                        tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>mise à jour",
                            `<div class='row centry'>
                                <i class='bx bxs-check-circle tl_fs-22' style='color: var(--app-secondary-color)'></i>
                            </div> 
                            votre mot de passe a bien été mise à jour et envoyé dans votre boîte email`
                        )
                    }
                    else {
                        // tl_alert("",response.result)
                        if (response.result === "U0") {
                            tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>Erreur",
                                `<div class='row centry'>
                                    <i class='bx bxs-x-circle tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                </div> 
                                le numéro n'existe pas`
                            )
                        }
                        else {
                            tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                                `<div class='row centry'>
                                    <i class='bx bx-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                </div> 
                                Oups! Une erreur s'est produite lors du changement de mot de passe`
                            )
                        }
                        
                    }
                }
                else {
                    tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                }
            }
        )
        // console.log(ft)
        

        // tl_alert("","Votre nouveau mot de passe vous à été envoyer par email, veuillez l'utiliser")
    }
    function clickAction (ev,func,action=()=>{}) {
        let e = ev.target
        let father = e.thisParent()
        if (!father.hasAttribute("focusmod")) {
            father.setAttribute("focusmod","true")
        }
        if (!father.hasAttribute("fillmod")) {
            father.setAttribute("fillmod","true")
        }
    
        if (timed < 5) {
            
            if (func === "mail") {
                e.value = userData.reg_umail
            }
            // if (func === "num") {
            //     e.value = userData.reg_utel
            // }
            if (func === "name") {
                e.value = userData.reg_uname
            }
            if (func === "pswd") {
                e.value = userData.reg_upswd
            }
            if (func === "num") {
                let hidele = e.thisParent().QSst("[type='hidden']")
                action(hidele,e)
            }
            else {
                action(e,ev)
            }
            
            setTimeout(() => {
                whenBlured(e)
            }, 50);
            timed++
        }
    }
    const numOptions = [
        { name: "Bénin", code: "BJ", value: "+229", src:process.env.PUBLIC_URL+"/images/flag-bj.png" },
        // { name: "Burkina Faso", code: "BF", value: "+226",src:process.env.PUBLIC_URL+"/images/flag-bf.png"  },
        // { name: "Côte d'Ivoire", code: "CI", value: "+225",src:process.env.PUBLIC_URL+"/images/flag-ci.png"  },
        // { name: "Gabon", code: "GA", value: "+241",src:process.env.PUBLIC_URL+"/images/flag-ga.png"  },
        // { name: "Niger", code: "NE", value: "+227", src:process.env.PUBLIC_URL+"/images/flag-ne.png" },
        // { name: "Togo", code: "TG", value: "+228",src:process.env.PUBLIC_URL+"/images/flag-tg.png" }
    ];
    // let [ready,setReady] = useState(false)
    useEffect (()=>{
        numNiem = 0
        mailNiem = 0
        nameNiem = 0
        passNiem = 0
        timed = 0
        setTimeout(() => {
            // Qs(".field").click()
            Qs(".field").forEach((value) => {value.click();})
        }, 800);
        Qs("mainScreen").addClass(screenClass)
    },[])
    let [editMode,setEditMode] = useState(false)
    function askChangingPaswword () {
        setEditMode(!editMode)
    }
    async function savePassword () {
        let response = await authenticate ()
        
        if (platform === 'ios') {
            // console.log('Exécution sur iOS');
        } else if (platform === 'android') {
            // console.log('Exécution sur Android');
        } else {
            response.status = true
        }
        if (response.status) {
            if (!realValidNewPass && !realValidNewPass) {
                if (!realValidNewPass) {
                    setValidNewPass(false)
                }
                if(!realValidOldPass) {
                    setValidOldPass(false)
                }
                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                    `<div class='row centry'>
                        <i class='bx bxs-x-circle tl_fs-22' style='color: var(--app-tertio-color)'></i>
                    </div> 
                    veuillez rempli les champs comme il se doit`
                )
                return
            }
            else {
                Qst('process-loader').setAttribute('inLoad',true)
                JSSend(
                    'POST',
                    "https://app.depotbetpro.com/backend/updater.php",
                    {"u_auth":true,"pswd":oldPass,"u_id":userData.id},
                    (result,response)=>{
                        if (result) {
                            if (response.status) {
                                // mettre à jour maintenant le mot de passe dans la db
                                JSSend(
                                    'POST',
                                    "https://app.depotbetpro.com/backend/updater.php",
                                    {"up_pass":true,"new_pass":newPass,"u_id":userData.id},
                                    (result,response)=>{
                                        if (result) {
                                            Qst('process-loader').removeAttribute('inLoad')
                                            if (response.status) {
                                                tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>mise à jour",
                                                    `<div class='row centry'>
                                                        <i class='bx bxs-check-circle tl_fs-22' style='color: var(--app-secondary-color)'></i>
                                                    </div> 
                                                    votre mot de passe a bien été mise à jour 😊!`
                                                )
                                                Qs(".field").forEach((value) => {value.click();})
                                                setEditMode(false)
                                            }
                                            else {
                                                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                                                    `<div class='row centry'>
                                                        <i class='bx bx-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                                    </div> 
                                                    Oups! Une erreur s'est produite lors de la mise à jour de votre mot de passe`
                                                )
                                            }
                                        }
                                        else {
                                            Qst('process-loader').removeAttribute('inLoad')
                                            tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                                        }
                                    }
                                )
                                
                            }
                            else {
                                Qst('process-loader').removeAttribute('inLoad')
                                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                                    `<div class='row centry'>
                                        <i class='bx bxs-x-circle tl_fs-22' style='color: var(--app-tertio-color)'></i>
                                    </div> 
                                    l'ancien mot de passe entré est incorrect!`
                                )
                                return
                            }
                        }
                    }
                )
            }
        }
        else {
            if (response.canceled) {
                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                    `<div class='row centry'>
                        <i class='bx bxs-x-circle tl_fs-22' style='color: var(--app-tertio-color)'></i>
                    </div> 
                    impossible de vous authentifier`
                )
            }
            else {
                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                    `<div class='row centry'>
                        <i class='bx bxs-x-circle tl_fs-22' style='color: var(--app-tertio-color)'></i>
                    </div> 
                    Une erreur est survenue lors de l'authentification`
                )
            }
        }
            
    }
    return (
        
            <Col className="profilDrawer">
                <div className="centry tl_flex-column">
                    {/* <Icon icon="bx-user" className="profilAvatr"/> */}
                    <img alt="imag" src={process.env.PUBLIC_URL+"/images/updateckeck1.gif"} width={25} className="profilAvatar"/>
                    <Text className="tl_mt-10" style={{color: "rgb(80,80,90)"}} content={"Vos informations et édition de profil "}/>
                </div>
                
                <form className='profil-form tl_gap-20' data-tp="up">
                    <TextField name="reg_uname" onClick={(e)=>{clickAction(e,"name",isValidName)}} invalidText="au moins 2 caractères" onBlur={whenBlured} isValid={ validName } onInput={isValidName} onFocus={isValidName} label="Votre nom" isRequired={true}/>
                    <TextField name="reg_umail" onClick={(e)=>{clickAction(e,"mail",mailCheck)}} invalidText="adresse email invalid" onBlur={whenBlured} isValid={ validMail } onInput={mailCheck} onFocus={mailCheck} type="email" label="Adresse email" isRequired={true}/>
                    <NumField options={numOptions} onClick={(e)=>{clickAction(e,"num",isValidNum)}} canUseInput={true} value={userData.reg_utel} onFocus={isValidNum} className="up_tel" name="reg_utel" isValid={validNum} invalidText="numéro invalid" onBlur={whenBlured} onInput={isValidNum} label="Numéro de téléphone"/>
                    <TextField name="reg_upswd" parentClass="tl_display-none" onClick={(e)=>{clickAction(e,"pswd",isValidPassword);Qs("bottom-navigation-bar").showThis("block")}} isValid={validPass} invalidText="au moins 8 caractères" onBlur={whenBlured} onFocus={isValidPassword} onInput={isValidPassword} type="password" label="Mot de passe" isRequired={true}/>
                    <Row className="tl_align-items-center tl_gap-20 tl_justify-content-spaceBetween">
                        <Text content="Modifier le mot de passe ?" className="tl_display-none tl_color-white tl_mv-10" />  
                        <TextAction className="tl_p-5 tl_border-radius-8 modifaction" onTouch={(e)=>{askChangingPaswword()}} content={<Row className="tl_align-items-center tl_gap-10"><span>Modifier le mot de passe</span><i className="bx bxs-shield"></i></Row>}/>
                    </Row>
                    {editMode ? 
                    <Col className="tl_mt-20 tl_gap-10 tl_opacity-6">
                        <TextField name="old_upswd" pswdIcon={true} onClick={(e)=>{clickAction(e,"oldpswd")}} isValid={validOldPass} invalidText="invalid" onBlur={whenBlured} onFocus={isValidOldPassword} onInput={isValidOldPassword} type="password" label="Ancien mot de passe" isRequired={true}/>
                        <TextField name="new_upswd" pswdIcon={true} onClick={(e)=>{clickAction(e,"newpswd")}} isValid={validNewPass} invalidText="au moins 8 caractères" onBlur={whenBlured} onFocus={isValidNewPassword} onInput={isValidNewPassword} type="password" label="Nouveau mot de passe" isRequired={true}/>
                        <Row className="tl_align-items-center tl_gap-20">
                            <Text content="Changer" className="tl_display-none tl_color-white tl_mv-10" />  
                            <TextAction className="tl_p-5 tl_border-radius-8 savemodifaction" onTouch={(e)=>{savePassword()}} content={<Row className="tl_align-items-center tl_gap-10"><span>changer</span><i className="bx bxs-check-circle"></i></Row>}/>
                        </Row>
                    </Col> : <></>}
                    <Row className="tl_align-items-center tl_gap-20 tl_justify-content-start">
                        <TextAction className="tl_p-5 tl_border-radius-8 authTuch3" onTouch={forgetPass} style={{backdropFilter: "blur(10px)",background: 'rgb(40,40,50,.4)', color: "var(--app-tertio-color)"}} content={<Row style={{pointerEvents:"none"}} className="tl_align-items-center tl_gap-10"><span style={{pointerEvents:"none"}}>Mot de passe oublié</span><i style={{pointerEvents:"none"}} className="bx bx-shield-quarter"></i></Row>}/>
                    </Row>
                    <input type="hidden" name="u_up" value={"true"} />
                    <input type="hidden" name="u_id" value={userData.id} />
                    <SubmitButton type="button" name="u_up" text={<Row className="centry"><Text content={"sauvegarder"} className="tl_fs-16"/><Icon icon="bxs-save" className="tl_ml-10 tl_fs-20"/> </Row>} id="up" subType="up" onClick={submitting} className="tl_mt-10 tl_text-center"/>
                </form>
            </Col>
    )

}

