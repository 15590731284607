import React,{ useEffect, useState } from "react";
import { TextField,NumField,DropDownMenu,SubmitButton, FullContainer, Text, Title, WhatsAppButton, Icon } from "../widgets/widgets.js";
import { Center, Col, Row } from "../widgets/layout_orient";
import { JSSend, Qst, mapToObject, thisFormData, tlGET, tl_alert } from "../@assets/tlBootstrap/tlBootstrap/JS/tl-js.js";
import { useNavigate, useParams } from 'react-router-dom';
import "./withdraw.css";
import { getGreeting, loading, waMeUrl } from "../function.js";
import { useUserData } from "../userContext.js";

let wFwDt

export default function WithDraw () {
    const { couponType } = useParams();
    let [stepIndex,setStepIndex] = useState("1");
    let [,setMethode] = useState("mtnbj");
    let [noteOpen,setnoteOpen] = useState("false");
    const [userData,set__] = useUserData();

    let minm = couponType==="1xbet"?900:1300
    let [validName,setValidName] = useState(true);
    let [validId,setValidId] = useState(true);
    let [validPass,setValidPass] = useState(true);
    let [validNum,setValidNum] = useState(true);
    let [validAmonut,setValidAmount] = useState(true);

    let [realValidName,setRealValidName] = useState(false);
    let [realValidId,setRealValidId] = useState(false);
    let [realValidPass,setRealValidPass] = useState(false);
    let [realValidNum,setRealValidNum] = useState(false);
    let [realValidAmount,setRealValidAmount] = useState(false);
    const Nav = useNavigate();

    async function returnToHome (e) {
        e.preventDefault()
        // window.location.replace(window.location.origin+"/home")
        window.history.pushState(null,null,tlGET.origin+"/home")
        await loading(2000);
        Nav(process.env.PUBLIC_URL+"/home",{replace:true})
        
    }
    function whenBlured (e) {
        if (e.value <= 0) {
            setValidId(true)
            setValidPass(true)
            setValidNum(true)
            setValidName(true)
            setValidAmount(true)
        }
        setnoteOpen("false")
    }
    function isValidId (e) {
        // e.value = e.value.slice(0, 10)
        if (e.value.length < 5 && e.value.length > 0 ) {
            setValidId(false)
        }
        else {
            setValidId(true)
            if (e.value.length < 5 ) {
                setRealValidId(false)
            }
            else {
                setRealValidId(true)
            }
        }
    }
    function isValidAmount (e) {
        if (parseInt(e.value) < minm && e.value.length > 0 ) {
            setValidAmount(false)
        }
        else {
            setValidAmount(true)
            if (parseInt(e.value) < minm ) {
                setRealValidAmount(false)
            }
            else {
                setRealValidAmount(true)
            }
        }
    }
    function isValidName (e) {
        if (e.value.length < 4 && e.value.length > 0 ) {
            setValidName(false)
        }
        else {
            setValidName(true)
            if (e.value.length < 4 ) {
                setRealValidName(false)
            }
            else {
                setRealValidName(true)
            }
        }
    }
    function isValidNum (ehid,e) {
        
        var input = e.value.replace(/\D/g, '');  // Supprime tout ce qui n'est pas un chiffre
        // Utilise regex pour formater par groupes de deux chiffres
        var formatted = input.slice(0,10).replace(/(\d{2})(?=\d)/g, '$1 ');
        e.value = formatted;
        if (ehid.value.length < 13 && ehid.value.length > 5 ) {
            setValidNum(false)
        }
        
        else {
            setValidNum(true)
            if (ehid.value.length < 13 ) {
                setRealValidNum(false)
            }
            else {
                setRealValidNum(true)
            }
        }
    }
    function isValidPass (e) {
        setnoteOpen("true")
        if (e.value.length < 3 && e.value.length > 0 ) {
            setValidPass(false)
        }
        
        else {
            setValidPass(true)
            if (e.value.length < 3 ) {
                setRealValidPass(false)
            }
            else {
                setRealValidPass(true)
            }
        }
    }
    useEffect(() => {
        JSSend(
            'POST',
            "https://app.depotbetpro.com/backend/getter.php",
            {"u_infos":true,"u_id":userData.id},
            (result,response)=>{
               if (result) {
                    if (response.status) {
                        // console.log(response.result)
                        set__(response.result)
                    }
               }
            }
        )
    });
    function submitting (e) {
        e.preventDefault()
        let theForm = e.target.thisParent()
        if (!theForm.hasAttribute("inauth")) {
            wFwDt = mapToObject(new Map(new thisFormData(theForm)))
            wFwDt[e.target.name]=true
            wFwDt["id"]=parseInt(userData.id)
            // console.log(wFwDt)
            theForm.setAttribute ("inauth","true")
            if (realValidName && realValidNum && realValidAmount && realValidId && realValidPass) {
                
                // tl_alert ("<i class='bx bx-check tl_fs-18' style='margin-right: 5px;transform: translateY(2px)'></i>Retrait en cours",
                //     `<div class='row centry'>
                //         <i class='bx bxs-check-circle tl_fs-22' style='color: var(--app-secondary-color)'></i>
                //     </div> 
                //     Demande de retrait en cours cous serai notifié une fois effectuée, merci de bien vouloir patienter 😊!`
                // )
                Qst('process-loader').setAttribute('inLoad',true)
                JSSend(
                    'POST',
                    "https://app.depotbetpro.com/backend/request.php",
                    wFwDt,
                    (result,response)=>{
                        // console.log(response)
                        if (result) {
                            if (response.status) {
                                setTimeout(() => {
                                    Qst('process-loader').removeAttribute('inLoad')
                                    // tl_alert ("info",methode)
                                    setStepIndex("2")
                                }, 2000);
                            }
                            else if (response.result === "Er0" ) {
                                Qst('process-loader').removeAttribute('inLoad')
                                theForm.removeAttribute ("inauth")
                                tl_alert('Alert',"Assurez-vous de remplir tous les champs comme il se doit")
                            }
                            else if (response.result === "InErr0" ) {
                                Qst('process-loader').removeAttribute('inLoad')
                                theForm.removeAttribute ("inauth")
                                tl_alert('Erreur',"Une erreur s'est produite lors de l'opération")
                            }
                        }
                        else {
                            Qst('process-loader').removeAttribute('inLoad')
                            theForm.removeAttribute ("inauth")
                            tl_alert('Erreur connexion',"Erreur de communication au serveur, veuillez vérifier votre connexion internet")
                        }
                    }
                )
            
            }
            else {
                if (!realValidId) {
                    setValidId(false)
                }
                if (!realValidName) {
                    setValidName(false)
                }
                if (!realValidNum) {
                    setValidNum(false)
                }
                if (!realValidPass) {
                    setValidPass(false)
                }
                if (!realValidAmount) {
                    setValidAmount(false)
                }
                tl_alert ("<i class='bx bx-x tl_fs-18' style='margin-right: 5px;transform: translateY(4px)'></i>erreur",
                    `<div class='row centry'>
                        <i class='bx bxs-shield-x tl_fs-22' style='color: var(--app-tertio-color)'></i>
                    </div> 
                    Assurez-vous de bien remplir les champs comme il se doit!`
                )
            }
        }
        
    }
    const options = [
        { name: "MTN Bénin", value: "mtnbj" },
        { name: "Moov Bénin", value: "moovbj" },
    ]
    const numOptions = [
        { name: "Bénin", code: "BJ", value: "+229", src:process.env.PUBLIC_URL+"/images/flag-bj.png" },
        // { name: "Burkina Faso", code: "BF", value: "+226",src:process.env.PUBLIC_URL+"/images/flag-bf.png"  },
        // { name: "Côte d'Ivoire", code: "CI", value: "+225",src:process.env.PUBLIC_URL+"/images/flag-ci.png"  },
        // { name: "Gabon", code: "GA", value: "+241",src:process.env.PUBLIC_URL+"/images/flag-ga.png"  },
        // { name: "Niger", code: "NE", value: "+227", src:process.env.PUBLIC_URL+"/images/flag-ne.png" },
        // { name: "Togo", code: "TG", value: "+228",src:process.env.PUBLIC_URL+"/images/flag-tg.png" }
    ];
    return (
        <Row className="withdrawThumb" thumb={stepIndex}>
            <Col className="form-slot tl_pt-30 tl_pb-30 tl_justify-content-spaceBetween">
                {/* <FullContainer>
                    <Center>
                        <img alt="imag" src="../../../images/withdraw.gif" className="circular" width={150} height={150} />
                    </Center>
                </FullContainer> */}
                {
                    userData.reg_allowed === "no"?
                    <FullContainer>
                        <Center className="tl_flex-column">
                            <img alt="imag" src={process.env.PUBLIC_URL+"/images/assistance.gif"} className="circular" width={200} height={200} />
                            <Text className={"tl_color-grey transition-all-ease-2 tl_fs-16 tl_text-center tl_ph-20"} content={"Oups! Vous n'êtes actuellement pas autorisé à effectuer des opérations. Veuillez contacter notre service technique pour en savoir plus"} />
                            <WhatsAppButton action={waMeUrl({number:"22962727170",message:`${getGreeting()}, c'est ${userData.reg_uname},\n
j'ai remarqué que mon compte a été bloqué, je voudrais en savoir plus`})}><Row className="centry tl_gap-10"><Text content="Nous contacter"/><img alt="imag" src={process.env.PUBLIC_URL+"/images/wame.gif"} className="circular" width={50} height={50} /></Row></WhatsAppButton>
                        </Center>
                    </FullContainer>: 
                    (couponType === "1xbet"?
                    <><FullContainer className="locateInfosC">
                        <Col className="locateInfos">
                            <Title style={{color:"var(--app-primary-color)"}} calibre={4} className="tl_text-justify tl_mb-15" content={"Adresse de retrait"}/>
                            <Row className="tl_gap-10 tl_align-items-center">
                                <div className="tl_w-30 tl_bg-white tl_display-flex tl_flex-column tl_align-items-center tl_border-radius-10">
                                    <img alt="imag" src={process.env.PUBLIC_URL+"/images/location.gif"} className="circular" width={60} height={60} /> 
                                </div>
                                <Col className="tl_gap-20">
                                    <Row className="tl_color-white tl_gap-10 tl_align-items-center">
                                        <strong style={{color:"var(--app-primary-color)"}}>Ville: </strong><Text content={"Abomey Calavi"}/>
                                    </Row>
                                    <Row className="tl_color-white tl_gap-10 tl_align-items-center">
                                        <strong style={{color:"var(--app-primary-color)"}}>Rue: </strong><Text content={"chez Depotbet pro"}/>
                                    </Row>
                                </Col>
                                <div className="tl_w-30 tl_bg- tl_display-flex tl_flex-column tl_align-items-center tl_border-radius-10">
                                    <img alt="imag" src={process.env.PUBLIC_URL+"/images/withdraw.gif"} className="circular" width={60} height={60} /> 
                                </div>
                            </Row>
                        </Col>
                    </FullContainer>
                    
                    <form className='withdraw-form tl_gap-20'>
                        <input type="hidden" name="coupon_type" value={couponType} />
                        <TextField name="bet_id" onBlur={whenBlured} type="number" invalidText="au moins 5 caractères" isValid={validId} onInput={isValidId} onFocus={isValidId} label={ "Indentifiant "+couponType } isRequired={true}/>
                        <TextField name="w_code" pswdIcon={true} onBlur={whenBlured} onInput={isValidPass} onFocus={isValidPass} invalidText="au moins 3 caractères" isValid={validPass} type="password" label="code de retrait" isRequired={true}/>
                        <input type="hidden" name="locate" value={"Abomey Calavi, chez Depotbet pro"}/>
                        <div className="w_note" show-note={noteOpen}>
                            Une fois le code généré depuis {couponType},copié le et collé le dans le champ ci-dessus ☝
                        </div>
                        <TextField name="bet_montant" onBlur={whenBlured} isValid={validAmonut} onInput={isValidAmount} onFocus={isValidAmount} invalidText={"au moins "+minm+" F"} type="number" label="Montant" isRequired={true}/>
                        <DropDownMenu options={options} onSelected={(ele)=>{setMethode(ele.value)}} name="w_method" label="moyen de retrait"/>
                        <NumField options={numOptions} name="r_num" isValid={validNum} invalidText="numéro invalid" onBlur={whenBlured} onFocus={isValidNum} onInput={isValidNum} label="Numéro récepteur"/>
                        <TextField name="r_name" invalidText="au moins 4 caractères" onBlur={whenBlured} isValid={ validName } onInput={isValidName} onFocus={isValidName} label="Nom complet du récepteur" isRequired={true}/>
                        <SubmitButton type="button" text="Retirer" name="u_w" id="w_" subType="w_" onClick={submitting} className="tl_mt-10"/>
                    </form></>
                    :
                    <FullContainer>
                        <Center className="tl_flex-column">
                            <img alt="assistance" src={process.env.PUBLIC_URL+"/images/assistance.gif"} className="circular" width={200} height={200} />
                            <Text className={"tl_color-grey transition-all-ease-2 tl_fs-16 tl_text-center tl_ph-20"} content={"Veuillez contacter notre service technique pour en savoir plus sur comment effectué un retrait de "+couponType} />
                            <WhatsAppButton action={waMeUrl({number:"22962727170",message:`${getGreeting()}, c'est ${userData.reg_uname},\n
je voudrais effectué un retrait de ${couponType}`})}><Row className="centry tl_gap-10"><Text content="Nous contacter"/><img alt="imag" src={process.env.PUBLIC_URL+"/images/wame.gif"} className="circular" width={50} height={50} /></Row></WhatsAppButton>
                        </Center>
                    </FullContainer>)
                }
                {/* <Col className="">
                    <Text content={"by ©thinolord | version 1.0.7"} className="tl_w-100 tl_mt-20 tl_text-center tl_color-grey" />
                </Col> */}
            </Col>
            <Col className="tl_mt-30 form-slot tl_pb-60 tl_justify-content-spaceBetween">
                <FullContainer>
                    <Center className="tl_flex-column tl_pt-30">
                        <img alt="timer" src={process.env.PUBLIC_URL+"/images/timer2.gif"} className="circular" width={200} height={200} />
                        <Text className={"tl_color-grey transition-all-ease-2 tl_fs-16 tl_text-center tl_ph-20"} content={"Votre opération de retrait est en cours de traitement, vous serez notifier une fois traitée"} />
                        <a className="wabtn" href="" rel="noopener noreferrer" onClick={returnToHome}><Row className="tl_align-items-center tl_gap-10"><Icon icon="bxs-left-arrow"/> Retour </Row></a>
                    </Center>
                </FullContainer>
                {/* <Col className="">
                    <Text content={"by ©thinolord | version 1.0.7"} className="tl_w-100 tl_text-center tl_color-grey" />
                </Col> */}
            </Col>
        </Row>
    )
}
